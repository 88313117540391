import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { Message, getLocale, useIntl } from '@myci/intl';
import { applySpec, compose } from 'ramda';
import { connect, useDispatch } from 'react-redux';
import { graphql, navigate } from 'gatsby';
import { withReducers } from 'redux-syringe';
import { getCurrentInstanceEnv, isNotNilNorEmpty } from '@myci/utils';
import {
	PricingTable,
	SelectedSubscriptionShape,
	USERS_DICT,
	USER_TYPE,
	UserShape,
} from '@myci/domain-subscriptions';
import {
	getSubscriptionPlan,
	getUserType,
	reducer as registration,
	setSubscriptionPlan,
	setUserType,
} from '@myci/domain-registration';
import {
	Box,
	ButtonLink,
	Carousel,
	Grid,
	Link,
	Section,
	SectionHeading,
} from '@myci/ui-components';
import { UserTypeSwitch } from '@myci/module-registration';
import { Storage, storageKeys } from '@myci/storage';

import { WelcomeSection } from '../components/WelcomeSection';
import { getLocaleSuffix } from '../features/localizeUrl';
import m from '../messages';
import { Badge, Img, SectionMessage } from '../components';
import { moduleConfig } from '../config';
import PublicLayout from '../layouts/PublicLayout';

export const indexPageAdditionalMenuContent = (
	<ButtonLink to="/registration" size="small">
		<Message {...m.getAnAccountLink} />
	</ButtonLink>
);

const Index = ({ data, selectedPlan, setSubscriptionPlan, userType, locale }) => {
	const {
		app,
		appStore,
		benefits,
		googlePlay,
		ciLogo,
		dashboard,
		worldMap,
		intro: {
			publicURL,
			internal: { mediaType },
		},
	} = data;
	useEffect(() => {
		if (isNotNilNorEmpty(selectedPlan)) {
			Storage.removeItem(storageKeys.TEMP_REGISTRATION_DATA);
		}
	}, []);

	const intl = useIntl();
	const dispatch = useDispatch();

	const { instanceName } = getCurrentInstanceEnv();

	const {
		settings: {
			isMobileAppEnabled,
			isBenefitsSectionEnabled,
			isWhoAreWeSectionEnabled,
			restrictedUserTypes,
			defaultUserType,
			storeLinks,
			themeOptions,
		},
	} = moduleConfig;

	const { hasAlternativeAppImage } = themeOptions ?? {};

	const handleSelectSubscription = plan => {
		setSubscriptionPlan(plan);
		navigate('registration');
	};

	const localeSuffix = getLocaleSuffix(locale);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!window.location.hash) return;

			const hashElement = document.querySelector(window.location.hash);

			if (!hashElement) {
				return;
			}

			window.scrollTo({
				top: hashElement.offsetTop - 70,
				behavior: 'smooth',
			});
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		dispatch(setUserType(USER_TYPE[defaultUserType]));
	}, [defaultUserType]);

	return (
		<PublicLayout
			className="bg-white"
			additionalMenuContent={indexPageAdditionalMenuContent}
			header={
				<Section kind="header">
					<WelcomeSection
						data={data}
						instanceName={instanceName}
						localeSuffix={localeSuffix}
						publicURL={publicURL}
						mediaType={mediaType}
					/>
				</Section>
			}
		>
			{isBenefitsSectionEnabled && (
				<Section id="benefits" menuLabel={<Message {...m.indexBenefitsTitle} />}>
					<Grid container>
						<Grid row alignItems="center">
							<Grid col={{ xs: 10, md: 5, lg: 4 }}>
								<Img data={benefits} className="img-fluid ml-xl-n4" alt="benefits" />
							</Grid>
							<Grid col={{ xs: 12, md: 6 }} offset={{ md: 1, lg: 2 }}>
								<SectionMessage {...m.indexBenefitsSection} ulProps={{ color: 'teal' }} />
							</Grid>
						</Grid>
					</Grid>
				</Section>
			)}
			<Section id="pricing" menuLabel={<Message {...m.indexPricingTitle} />}>
				<Grid container>
					<Grid row>
						<Grid col={12} mb={{ xs: 3, md: 4 }}>
							<SectionHeading as="h2" mb={{ xs: 3, md: 4 }} align={{ md: 'center' }}>
								<Message {...m.indexPricingTitleSection} />
							</SectionHeading>
							<Grid alignItems="baseline" justifyContent="center" flexDirection={['column', 'row']}>
								<Grid
									as="p"
									alignItems="baseline"
									mb={{ xs: 3, md: 0 }}
									mr={{ sm: 3, lg: 4 }}
									className="color-info"
								>
									<Badge number={1} text={<Message {...m.indexBadgeChooseYourSub} />} />
								</Grid>
								<Grid
									as="p"
									alignItems="baseline"
									mb={{ xs: 3, md: 0 }}
									mr={{ sm: 3, lg: 4 }}
									className="color-info"
								>
									<Badge number={2} text={<Message {...m.indexBadgeFillInRegistration} />} />
								</Grid>
								<Grid as="p" alignItems="baseline" mb={{ xs: 3, md: 0 }} className="color-info">
									<Badge number={3} text={<SectionMessage {...m.indexBadgeAuthorization} />} />
								</Grid>
							</Grid>
						</Grid>
						{(!restrictedUserTypes || restrictedUserTypes?.length === 0) && (
							<Grid col={12} mb={{ xs: 3, lg: 4 }} className="text-center">
								<UserTypeSwitch />
							</Grid>
						)}
						<Grid col={12}>
							<div id="subscriptions">
								<PricingTable
									userType={USERS_DICT[userType]}
									onSelect={handleSelectSubscription}
									selectedPlan={selectedPlan}
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Section>
			<Section className="section--sneakpeek">
				<Grid container>
					<Grid row>
						<Grid col={12}>
							<SectionHeading as="h2" align={{ md: 'center' }} mb={2}>
								<Message {...m.indexSneakpeekTitle} />
							</SectionHeading>
						</Grid>
					</Grid>
				</Grid>
				<Grid container fluid px={0}>
					<Grid row className="no-gutters">
						<Grid col={12}>
							<Carousel options={{ containerClass: 'swiper-container pt-3 pt-md-4 pb-4 pb-md-5' }}>
								<Img
									data={
										data[`sneakPeekFirstSlide_${instanceName}_${localeSuffix}`] ??
										data[`sneakPeekFirstSlide_${instanceName}`]
									}
									className="carousel__img"
								/>
								<Img
									data={
										data[`sneakPeekSecondSlide_${instanceName}_${localeSuffix}`] ??
										data[`sneakPeekSecondSlide_${instanceName}`]
									}
									className="carousel__img"
								/>
								<Img
									data={
										data[`sneakPeekThirdSlide_${instanceName}_${localeSuffix}`] ??
										data[`sneakPeekThirdSlide_${instanceName}`]
									}
									className="carousel__img"
								/>
							</Carousel>
						</Grid>
					</Grid>
				</Grid>
				<Grid container>
					<Grid flex my={{ xs: 3, md: 4 }} justifyContent="center">
						<Box mx={2}>
							<SectionMessage liProps={{ mb: 2 }} {...m.indexSneakpeekChecklistStart} />
						</Box>
						<Box mx={2}>
							<SectionMessage liProps={{ mb: 2 }} {...m.indexSneakpeekChecklistEnd} />
						</Box>
					</Grid>
					<Box className="text-center">
						<ButtonLink to="/registration">
							<Message {...m.indexSneakpeekAction} />
						</ButtonLink>
					</Box>
				</Grid>
			</Section>
			{isWhoAreWeSectionEnabled && (
				<Section id="who-are-we" menuLabel={<Message {...m.indexWhoAreWeTitle} />}>
					<Grid container>
						<Grid row>
							<Grid col={12}>
								<SectionHeading as="h2" align={{ md: 'center' }} mb={2}>
									<Message {...m.indexWhoAreWeTitle} />
								</SectionHeading>
							</Grid>
						</Grid>
						<Grid row my={4} justifyContent="center" mt={4}>
							<Grid col={{ xs: 12, md: 6, lg: 4 }} offset={{ lg: 1 }} order={{ xs: 2, md: 1 }}>
								<SectionMessage h3Props={{ mb: 2 }} {...m.indexWhoAreWeSection_aboutCreditInfo} />
							</Grid>
							<Grid
								col={{ xs: 12, md: 6, lg: 4 }}
								offset={{ lg: 1 }}
								order={{ xs: 1, md: 2 }}
								justifyContent="center"
								alignItems="center"
							>
								<Img data={ciLogo} height={100} alt={intl.formatMessage(m.ciLogoAltText)} />
							</Grid>
						</Grid>

						<Grid row my={4} justifyContent="center">
							<Grid
								col={{ xs: 12, md: 6, lg: 4 }}
								offset={{ lg: 1 }}
								justifyContent="center"
								alignItems="center"
							>
								<Img data={dashboard} alt={intl.formatMessage(m.dashboardAltText)} />
							</Grid>
							<Grid col={{ xs: 12, md: 6, lg: 4 }} offset={{ lg: 1 }}>
								<SectionMessage
									h3Props={{ mb: 2 }}
									{...m.indexWhoAreWeSection_aboutLocalCreditBureau}
								/>
							</Grid>
						</Grid>
						<Grid row my={4} justifyContent="center">
							<Grid col={{ xs: 12, md: 6, lg: 4 }} offset={{ lg: 1 }} order={{ xs: 2, md: 1 }}>
								<SectionMessage
									h3Props={{ mb: 2 }}
									{...m.indexWhoAreWeSection_ourDigitalFootprint}
								/>
							</Grid>
							<Grid
								col={{ xs: 12, md: 6, lg: 4 }}
								offset={{ lg: 1 }}
								order={{ xs: 1, md: 2 }}
								justifyContent="center"
								alignItems="center"
							>
								<Img data={worldMap} alt={intl.formatMessage(m.worldMapAltText)} />
							</Grid>
						</Grid>
					</Grid>
				</Section>
			)}
			{isMobileAppEnabled && (
				<Section id="myci-app" menuLabel={<Message {...m.indexMyCiAppTitle} />}>
					<Grid container>
						<Grid row>
							<Grid col={{ xs: 12, md: 6, lg: 5 }} pt={{ xs: 3, lg: 3, xl: 4 }} offset={{ lg: 1 }}>
								<SectionMessage
									{...m.indexFooterSection}
									pProps={{ color: 'white' }}
									h2Props={{ color: 'white' }}
								/>
								<Grid justifyContent={{ xs: 'center', md: 'start' }}>
									<Link href={storeLinks?.apple} mr={[3, 3, 4]}>
										<Img data={appStore} alt="appstore" />
									</Link>
									<Link href={storeLinks?.google}>
										<Img data={googlePlay} alt="google play" />
									</Link>
								</Grid>
							</Grid>
							<Grid
								col={{ xs: 12, md: 6, lg: 6 }}
								mt={{ xs: 4, md: 0 }}
								justifyContent={{ xs: 'center', sm: 'start' }}
							>
								{hasAlternativeAppImage ? (
									<Img
										data={
											data[`appAlternative_${instanceName}_${localeSuffix}`] ??
											data[`appAlternative_${instanceName}`]
										}
										className="app-alternative-img img-fluid"
										alt={intl.formatMessage(m.mobileAppImageAlt)}
									/>
								) : (
									<Img
										data={app}
										className="app-img img-fluid"
										alt={intl.formatMessage(m.mobileAppImageAlt)}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Section>
			)}
		</PublicLayout>
	);
};

export const query = graphql`
	query {
		app: file(relativePath: { eq: "images/app.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		appAlternative_omn: file(relativePath: { eq: "images/app/omn/app-alternative.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		appAlternative_omn_ar: file(relativePath: { eq: "images/app/omn/app-alternative-ar.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		appStore: file(relativePath: { eq: "images/app-store.svg" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		benefits: file(relativePath: { eq: "images/benefits.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		googlePlay: file(relativePath: { eq: "images/play-store.svg" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		intro: file(relativePath: { eq: "videos/intro.mp4" }) {
			publicURL
			internal {
				mediaType
			}
		}
		introAlternative_omn: file(relativePath: { eq: "images/intro/omn/intro-alternative.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		introAlternative_omn_ar: file(
			relativePath: { eq: "images/intro/omn/intro-alternative-ar.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_lka: file(relativePath: { eq: "images/sneak-peek/lka/slide-001.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_lka: file(relativePath: { eq: "images/sneak-peek/lka/slide-002.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_lka: file(relativePath: { eq: "images/sneak-peek/lka/slide-003.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_lva: file(relativePath: { eq: "images/sneak-peek/lva/slide-001.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_lva: file(relativePath: { eq: "images/sneak-peek/lva/slide-002.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_omn: file(relativePath: { eq: "images/sneak-peek/omn/slide-001.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_omn: file(relativePath: { eq: "images/sneak-peek/omn/slide-002.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_omn: file(relativePath: { eq: "images/sneak-peek/omn/slide-003.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_omn_ar: file(
			relativePath: { eq: "images/sneak-peek/omn/slide-001-ar.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_omn_ar: file(
			relativePath: { eq: "images/sneak-peek/omn/slide-002-ar.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_omn_ar: file(
			relativePath: { eq: "images/sneak-peek/omn/slide-003-ar.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_mar: file(relativePath: { eq: "images/sneak-peek/mar/slide-001.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_mar: file(relativePath: { eq: "images/sneak-peek/mar/slide-002.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_mar: file(relativePath: { eq: "images/sneak-peek/mar/slide-003.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_mar_ar: file(
			relativePath: { eq: "images/sneak-peek/mar/slide-001-ar.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_mar_ar: file(
			relativePath: { eq: "images/sneak-peek/mar/slide-002-ar.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_mar_ar: file(
			relativePath: { eq: "images/sneak-peek/mar/slide-003-ar.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_mar_fr: file(
			relativePath: { eq: "images/sneak-peek/mar/slide-001-fr.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_mar_fr: file(
			relativePath: { eq: "images/sneak-peek/mar/slide-002-fr.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_mar_fr: file(
			relativePath: { eq: "images/sneak-peek/mar/slide-003-fr.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_stp: file(relativePath: { eq: "images/sneak-peek/stp/slide-001.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_stp: file(relativePath: { eq: "images/sneak-peek/stp/slide-002.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_stp: file(relativePath: { eq: "images/sneak-peek/stp/slide-003.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_stp_pt: file(
			relativePath: { eq: "images/sneak-peek/stp/slide-001-pt.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_stp_pt: file(
			relativePath: { eq: "images/sneak-peek/stp/slide-002-pt.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_stp_pt: file(
			relativePath: { eq: "images/sneak-peek/stp/slide-003-pt.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_syc: file(relativePath: { eq: "images/sneak-peek/syc/slide-001.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_syc: file(relativePath: { eq: "images/sneak-peek/syc/slide-002.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_syc: file(relativePath: { eq: "images/sneak-peek/syc/slide-003.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekFirstSlide_syc_fr: file(
			relativePath: { eq: "images/sneak-peek/syc/slide-001-fr.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekSecondSlide_syc_fr: file(
			relativePath: { eq: "images/sneak-peek/syc/slide-002-fr.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		sneakPeekThirdSlide_syc_fr: file(
			relativePath: { eq: "images/sneak-peek/syc/slide-003-fr.png" }
		) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		ciLogo: file(relativePath: { eq: "images/ci.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		worldMap: file(relativePath: { eq: "images/world-map.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		dashboard: file(relativePath: { eq: "images/dashboard.svg" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
	}
`;

Index.propTypes = {
	locale: PropTypes.string,
	selectedPlan: SelectedSubscriptionShape,
	setSubscriptionPlan: PropTypes.func,
	setUserType: PropTypes.func,
	userType: UserShape,
};

export default compose(
	withReducers({ registration }, { isGlobal: true, isPersistent: true }),
	connect(
		applySpec({
			selectedPlan: getSubscriptionPlan,
			userType: getUserType,
			locale: getLocale,
		}),
		{
			setSubscriptionPlan,
		}
	),
	memo
)(Index);
