import React, { ReactNode } from 'react';
import { Icon } from '@ci/atoms';
import { Message } from '@myci/intl';
import { useInstanceConfig } from '@myci/instances';
import { prepareStyle, useStyles } from '@ci/styles';
import { AnchorLink, ButtonLink, Video } from '@myci/ui-components';

import m from '../../messages';
import Img from '../Img';
import SectionMessage from '../SectionMessage';

interface WelcomeSectionProps {
	customButton?: ReactNode;
	data: string[];
	instanceName: string;
	localeSuffix: string;
	mediaType: string;
	publicURL: string;
}

const welcomeSectionWrapperStyle = prepareStyle(utils => ({
	display: 'flex',
	justifyContent: 'space-around',
	padding: `0 ${utils.spacings.md}`,
	width: '100%',
}));

const welcomeSectionContainerStyle = prepareStyle(utils => ({
	display: 'flex',
	flexDirection: 'column',
	maxWidth: utils.maxWidths.sm,
	selectors: {
		[utils.breakpoints.md]: {
			flexDirection: 'row',
			maxWidth: utils.maxWidths.md,
		},
		[utils.breakpoints.lg]: {
			maxWidth: utils.maxWidths.lg,
		},
		[utils.breakpoints.xl]: {
			margin: `0 ${utils.spacings.md}`,
			maxWidth: utils.maxWidths.xl,
		},
	},
}));

const welcomeMessageContainerStyle = prepareStyle(utils => ({
	display: 'flex',
	flex: '0 1 100%',
	flexDirection: 'column',
	order: 2,
	padding: `0 ${utils.spacings.sm}`,
	selectors: {
		[utils.breakpoints.md]: {
			flex: '0 1 50%',
			order: 1,
		},
		[utils.breakpoints.xl]: {
			flex: '0 1 40%',
			margin: `0 ${utils.spacings.sm}`,
		},
	},
}));

const registrationButtonStyle = prepareStyle(() => ({
	alignSelf: 'flex-start',
}));

const introContainerStyle = prepareStyle(utils => ({
	display: 'flex',
	flex: '0 1 100%',
	order: 1,
	padding: `${utils.spacings.md} ${utils.spacings.sm}`,
	selectors: {
		[utils.breakpoints.md]: {
			flex: '0 1 50%',
			order: 2,
			padding: `0 ${utils.spacings.sm}`,
		},
		[utils.breakpoints.xl]: {
			flex: '0 1 60%',
		},
	},
}));

const introAlternativeStyle = prepareStyle(utils => ({
	marginTop: utils.spacings.sm,
	maxWidth: '100%',
	selectors: {
		[utils.breakpoints.md]: {
			marginTop: '0',
		},
	},
}));

const introVideoStyle = prepareStyle(utils => ({
	display: 'block',
	marginTop: 0,
	paddingBottom: utils.spacings.md,
	paddingTop: utils.spacings.md,
	width: '100%',
	selectors: {
		[utils.breakpoints.md]: {
			paddingBottom: 0,
			paddingTop: 0,
		},
	},
}));

const anchorLinkContainerStyle = prepareStyle(utils => ({
	display: 'none',
	paddingTop: utils.spacings.md,
	textAlign: 'center',
	width: '100%',
	selectors: {
		[utils.breakpoints.md]: {
			display: 'block',
		},
	},
}));

export const WelcomeSection = ({
	data,
	instanceName,
	localeSuffix,
	mediaType,
	publicURL,
	customButton,
}: WelcomeSectionProps) => {
	const { applyStyle } = useStyles();

	const { isBenefitsSectionEnabled, themeOptions } = useInstanceConfig();
	const { hasAlternativeIntro } = themeOptions ?? {};
	const chevronAnchorLinkTarget = isBenefitsSectionEnabled ? 'benefits' : 'pricing';

	return (
		<>
			<div className={applyStyle(welcomeSectionWrapperStyle)}>
				<div className={applyStyle(welcomeSectionContainerStyle)}>
					<div className={applyStyle(welcomeMessageContainerStyle)}>
						<SectionMessage {...m.indexHeroSection} />
						{customButton ?? (
							<ButtonLink to="/registration" customStyle={registrationButtonStyle}>
								<Message {...m.getAnAccountLink} />
							</ButtonLink>
						)}
					</div>
					<div className={applyStyle(introContainerStyle)}>
						{hasAlternativeIntro ? (
							<Img
								className={applyStyle(introAlternativeStyle)}
								data={
									data[`introAlternative_${instanceName}_${localeSuffix}`] ??
									data[`introAlternative_${instanceName}`]
								}
							/>
						) : (
							<Video
								src={publicURL}
								mediaType={mediaType}
								className={applyStyle(introVideoStyle)}
								autoPlay
								muted
								loop
								playsInline
							/>
						)}
					</div>
				</div>
			</div>
			<div className={applyStyle(anchorLinkContainerStyle)}>
				<AnchorLink id={chevronAnchorLinkTarget}>
					<Icon type="chevronDown" size="lg" color="secondary" />
				</AnchorLink>
			</div>
		</>
	);
};
