export const getDoesFileExist = path => {
	try {
		return require(`${path}`);
	} catch {
		return null;
	}
};

export const getLocalizedUrl = (locale, url = '') => {
	const suffix = locale.replace(/-.*/, '').toLowerCase();

	return url.split('.').join(`_${suffix}.`);
};

export const getLocaleSuffix = locale => locale.replace(/-.*/, '').toLowerCase();

export const getLocalizedImageDataObject = (locale, data) => {
	const { publicURL, childImageSharp } = data;
	const localizedUrl = getLocalizedUrl(locale, publicURL);

	if (!getDoesFileExist(localizedUrl)) return data;

	const localizedImageData = {
		publicURL: localizedUrl,
		childImageSharp: null,
	};

	if (childImageSharp) {
		const { aspectRatio, layout, sizes, srcSet } = childImageSharp;
		localizedImageData.childImageSharp = {
			aspectRatio,
			layout,
			sizes,
			src: localizedUrl,
			srcSet: getLocalizedUrl(locale, srcSet),
		};
	}

	return localizedImageData;
};
